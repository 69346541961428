.container-select {
  width: 100%;
  margin-left: 20px;
}

.mt-5 {
  margin-top: 20px;
}

.containerBtn {
  display: flex;
  margin: 0px 10px;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
.wFull {
  width: 100%;
}

#buttonPreAuthorized:disabled {
  color: rgba(255, 255, 255, 0.61);
}

.containerBtn {
    display: flex;
    margin: 0px 10px;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}
